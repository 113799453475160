import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { useEffect, useState } from "react"

const useFirebaseApp = () => {
	const [app, setApp] = useState(null)

	useEffect(() => {
		const firebaseConfig = {
			apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
			authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
			databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
			projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
			storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
			appId: process.env.REACT_APP_FIREBASE_APP_ID,
			measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
		}

		const firebaseApp = initializeApp(firebaseConfig)
		setApp(firebaseApp)
	}, [])

	const getFirebaseInstance = () => {
		return app
	}

	const getFirestoreInstance = () => {
		const firestore = getFirestore(app)
		return firestore
	}

	const getFirebaseStorage = () => {
		const storage = getStorage(app)
		return storage
	}

	return { getFirebaseInstance, getFirestoreInstance, getFirebaseStorage }
}

export default useFirebaseApp
