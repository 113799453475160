import { FetchById } from "common/fetcherHOC"
import { adminApi } from "services/modules"

import SermonForm from "./form"

export const SermonEdit = FetchById(
	function SermonEdit({ response: { data } }) {
		return <SermonForm save={adminApi.usePutSermonSummaryMutation} initialValues={data} />
	},
	adminApi.useGetSermonSummaryDetailQuery,
	["weekly_date"]
)

export const SermonWrite = () => {
	return (
		<SermonForm
			save={adminApi.usePostSermonSummaryMutation}
			initialValues={{
				content: Array.from({ length: 3 }, () => ({
					id: Date.now() + Math.random(),
					sub_cont: "",
					summary: [],
				})),
			}}
		/>
	)
}
