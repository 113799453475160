import MDTypography from "components/MDTypography"
import PropTypes from "prop-types"

const ErrorMessage = ({ message, ...rest }) => {
	return (
		<MDTypography mt={1} color="error" {...rest} display="block" variant="caption" minHeight="1rem">
			{message}
		</MDTypography>
	)
}

ErrorMessage.propTypes = {
	message: PropTypes.string.isRequired,
}

export default ErrorMessage
