import { Dialog } from "@mui/material"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import PropTypes from "prop-types"

const SimpleDialog = ({ open, onClose, onSuccess, message }) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<MDBox p={4}>
				<MDBox mb={2}>{message}</MDBox>
				<MDBox display="flex" justifyContent="center" gap={2}>
					<MDButton color="secondary" onClick={onClose}>
						취소
					</MDButton>
					<MDButton color="error" onClick={onSuccess}>
						삭제
					</MDButton>
				</MDBox>
			</MDBox>
		</Dialog>
	)
}

SimpleDialog.defaultProps = {
	open: false,
	onClose: () => {},
	onSuccess: () => {},
	message: "",
}

SimpleDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	message: PropTypes.string.isRequired,
}

export default SimpleDialog
