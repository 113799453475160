import { yupResolver } from "@hookform/resolvers/yup"
import { Card, FormLabel, Grid, TextField, Typography } from "@mui/material"
import DatePicker from "components/DatePicker"
import ErrorMessage from "components/ErrorMessage"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import PropTypes from "prop-types"
import { useLayoutEffect } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useNavigate } from "react-router-dom"

export default function Form({
	formFields,
	initialValues = {},
	validationSchema,
	onSubmit,
	submitButtonDisabled,
}) {
	const navigate = useNavigate()

	const methods = useForm({
		defaultValues: initialValues,
		resolver: validationSchema && yupResolver(validationSchema),
		mode: "onBlur",
	})

	const { handleSubmit } = methods

	useLayoutEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<FormProvider {...methods}>
			<form noValidate onSubmit={handleSubmit(onSubmit)}>
				{formFields?.map(({ label, fields }, index) => (
					<Card key={`form-${label}-${index}`} sx={{ p: 4 }}>
						{label && <Typography variant="h4">{label}</Typography>}

						<Grid container spacing={2} pt={2}>
							{fields.map((field, idx) => {
								const { name, title, type, ...rest } = field

								return (
									<Field
										key={`${index}-${name}-${idx}`}
										name={name}
										title={title}
										type={type}
										{...rest}
									/>
								)
							})}
						</Grid>
					</Card>
				))}
				<MDBox display="flex" justifyContent="center" alignItems="center" gap={2}>
					<MDButton
						sx={{ mt: 2 }}
						disabled={submitButtonDisabled}
						color="error"
						variant="gradient"
						size="large"
						onClick={() => navigate(-1)}
					>
						취소하기
					</MDButton>
					<MDButton
						sx={{ mt: 2 }}
						disabled={submitButtonDisabled}
						color="info"
						size="large"
						type="submit"
					>
						등록하기
					</MDButton>
				</MDBox>
			</form>
		</FormProvider>
	)
}

export function Field({ name, title, type, Component, ...rest }) {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext()

	switch (type) {
		case "date":
			return (
				<ItemWrapper title={title} md={rest.md} error={errors[name]}>
					<DatePicker key={name} name={name} control={control} {...rest} />
				</ItemWrapper>
			)
		case "custom":
			return (
				<ItemWrapper title={title} md={rest.md}>
					{Component}
				</ItemWrapper>
			)

		default:
			return (
				<ItemWrapper title={title} md={rest.md} error={errors[name]}>
					<TextField
						key={name}
						{...register(name)}
						type={type}
						name={name}
						onWheel={(e) => e.target.blur()}
						fullWidth
						title={title}
						{...rest}
					/>
				</ItemWrapper>
			)
	}
}

export function ItemWrapper({ children, title, xs, md, error = {} }) {
	return (
		<Grid item xs={xs} md={md} container direction="column" justifyContent="flex-start">
			{title && <FormLabel>{title}</FormLabel>}

			{children}

			<ErrorMessage message={error?.message} />
		</Grid>
	)
}

Form.propTypes = {
	formFields: PropTypes.array.isRequired,
	initialValues: PropTypes.object,
	validationSchema: PropTypes.object,
	onSubmit: PropTypes.func.isRequired,
	submitButtonDisabled: PropTypes.bool,
}

Field.propTypes = {
	name: PropTypes.string.isRequired,
	title: PropTypes.string,
	type: PropTypes.string,
	Component: PropTypes.node,
}

ItemWrapper.defaultProps = {
	xs: 12,
	md: 6,
}

ItemWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	xs: PropTypes.oneOf([6, 12]),
	md: PropTypes.oneOf([6, 12]),
	error: PropTypes.object,
}
