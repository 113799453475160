import Form from "components/Form"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { openSnackbar } from "stores/slice/snackbar"
import { ROUTE_SERMON } from "urls"
import { array, object, string } from "yup"

import { SermonContents } from "./components"

const sermonWriteSchema = object({
	title: string().required("제목을 입력해주세요."),
	bible_verse: string().required("본문 구절을 입력해주세요."),
	preacher_nm: string().required("설교자를 입력해주세요."),
	content: array().of(
		object({
			sub_cont: string().required("대지 내용을 입력해주세요."),
			summary: array()
				.of(
					object({
						smry_id: string(),
						smry_cont: string()
							.required("설교 요약을 입력해주세요.")
							.test(
								"is-summary-not-empty",
								"설교 요약은 공백이 될 수 없습니다.",
								(value) => value.trim() !== ""
							),
					})
				)
				.nullable(),
		})
	),
	weekly_date: string().required("날짜를 선택해주세요."),
	srmn_id: string().nullable(),
}).required()

export default function SermonForm({ save, initialValues }) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const fields = [
		{
			label: "설교 정보",
			fields: [
				{
					title: "설교 제목",
					name: "title",
				},
				{
					title: "성경 구절",
					name: "bible_verse",
				},
				{
					title: "설교자",
					name: "preacher_nm",
				},
				{
					title: "날짜",
					name: "weekly_date",
					type: "date",
				},
				{
					title: "내용",
					name: "content",
					type: "custom",
					md: 12,
					Component: <SermonContents />,
				},
			],
		},
	]

	const [saveTrigger, { isLoading }] = save()

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<Form
				formFields={fields}
				validationSchema={sermonWriteSchema}
				onSubmit={async (data) => {
					try {
						await saveTrigger(data).unwrap()

						dispatch(openSnackbar({ message: "설교 요약이 등록되었습니다." }))

						navigate(ROUTE_SERMON)
					} catch (error) {
						dispatch(
							openSnackbar({
								severity: "error",
								message: error?.message || "오류가 발생했습니다.",
							})
						)
					}
				}}
				submitButtonDisabled={isLoading}
				initialValues={initialValues}
			/>
		</DashboardLayout>
	)
}

SermonForm.propTypes = {
	create: PropTypes.bool,
	initialValues: PropTypes.object,
	isEditing: PropTypes.bool,
	save: PropTypes.func,
}
