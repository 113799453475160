import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import React from "react"

function FormLoading() {
	return (
		<Backdrop sx={{ color: "#00369F", zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
			<CircularProgress color="inherit" />
		</Backdrop>
	)
}

export default FormLoading
