import { ROUTE_ATEENDANCE, ROUTE_PRAYS, ROUTE_PROGRAMS, ROUTE_SERMON } from "urls"

const SCREEN_NAMES = {
	[ROUTE_ATEENDANCE]: "출석 관리",
	[ROUTE_PRAYS]: "기도 목록",
	[ROUTE_SERMON]: "설교 요약",
	[ROUTE_PROGRAMS]: "주보 관리",
}

export const getScreenName = (route) => {
	if (!route) return ""

	const parsedRoute = route.split("/").slice(1)
	const screenName =
		SCREEN_NAMES[Object.keys(SCREEN_NAMES).find((key) => key.includes(parsedRoute[0]))]
	return screenName ? screenName : route
}
