import ForcedAppVersionCheck from "components/ForcedAppVersionCheck"
import CustomSnackbar from "components/Snackbar"
import { MaterialUIControllerProvider } from "context"
import PropTypes from "prop-types"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/lib/integration/react"
import storeObject from "stores/store"

export default function AppWrapper({ children }) {
	return (
		<BrowserRouter>
			<MaterialUIControllerProvider>
				<Provider store={storeObject.store}>
					<PersistGate persistor={storeObject.persistor}>
						<ForcedAppVersionCheck />
						{children}
						<CustomSnackbar />
					</PersistGate>
				</Provider>
			</MaterialUIControllerProvider>
		</BrowserRouter>
	)
}

AppWrapper.propTypes = {
	children: PropTypes.node.isRequired,
}
