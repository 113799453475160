import { Card, Grid } from "@mui/material"
import { ItemWrapper } from "components/Form"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import PropTypes from "prop-types"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { adminApi } from "services/modules"
import { ROUTE_SERMON } from "urls"

const SermonDetail = () => {
	const { id } = useParams()
	const { state } = useLocation()
	const navigate = useNavigate()

	const { data } = adminApi.useGetSermonSummaryDetailQuery({ weekly_date: state?.weekly_date })
	const { data: { bible_verse, content, title, preacher_nm } = {} } = data || {}

	const updateHandler = () => {
		navigate(`${ROUTE_SERMON}/${id}/modify`, { state: { weekly_date: state?.weekly_date } })
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox p={3}>
				<Card>
					<MDBox p={4}>
						<MDTypography variant="h4">설교 정보</MDTypography>

						<Grid container spacing={2} pt={3}>
							<FieldWrapper title="설교 제목" value={title} />
							<FieldWrapper title="본문 말씀" value={bible_verse} />
							<FieldWrapper title="설교자" value={preacher_nm} />
							<FieldWrapper title="날짜" value={state?.weekly_date} />

							<FieldWrapper title="설교 내용" md={12}>
								{content?.map(({ sub_id, sub_cont, summary }, index) => (
									<MDBox key={sub_id} mt={2}>
										<MDTypography variant="h6">{`${index + 1}. ${sub_cont}`}</MDTypography>
										<MDBox mt={1}>
											{summary.map(({ smry_cont, smry_id }) => (
												<MDTypography key={smry_id} variant="body2">
													- {smry_cont}
												</MDTypography>
											))}
										</MDBox>
									</MDBox>
								))}
							</FieldWrapper>
						</Grid>
					</MDBox>
				</Card>
				<MDBox mt={3} display="flex" justifyContent="center" gap={2}>
					<MDButton variant="gradient" color="primary" size="large" onClick={updateHandler}>
						수정하기
					</MDButton>
					<MDButton variant="gradient" color="secondary" size="large" onClick={() => navigate(-1)}>
						목록으로
					</MDButton>
				</MDBox>
			</MDBox>
		</DashboardLayout>
	)
}

const FieldWrapper = ({ children, title, value, ...rest }) => (
	<ItemWrapper title={title} {...rest}>
		{children ? (
			children
		) : (
			<MDTypography variant="h6" gutterBottom>
				{value}
			</MDTypography>
		)}
	</ItemWrapper>
)

FieldWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
}

export default SermonDetail
