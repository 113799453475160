import ErrorMessage from "components/ErrorMessage"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDInput from "components/MDInput"
import MDTypography from "components/MDTypography"
import { compact, find, flatMap, get } from "lodash"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"

const SermonContents = () => {
	const {
		setValue,
		watch,
		formState: { errors },
	} = useFormContext()

	const watchContent = watch("content")

	const addFormHandler = () => {
		const sermonContents = watchContent || []
		const newSermonContents = [...sermonContents, { id: Date.now(), sub_cont: "", summary: [""] }]
		setValue("content", newSermonContents)
	}

	return (
		<MDBox>
			<MDBox display="flex" flexDirection="row" gap={2} alignItems="center">
				<MDButton variant="gradient" color="primary" size="large" onClick={addFormHandler}>
					대지 추가
				</MDButton>
			</MDBox>
			{watchContent?.map((content, index) => (
				<SermonContentsItem key={content.id} index={index} />
			))}
			{getFirstErrorMessage(errors) && <ErrorMessage message={getFirstErrorMessage(errors)} />}
		</MDBox>
	)
}

const SermonContentsItem = ({ index }) => {
	const { setValue, watch, control, trigger } = useFormContext()

	const watchContent = watch("content") || []

	const removeFormHandler = () => {
		const updatedContents = watchContent.filter((_, i) => i !== index)
		setValue("content", updatedContents)
		trigger("content")
	}

	const addSummaryHandler = () => {
		const updatedContents = watchContent || []
		updatedContents[index].summary.push({ smry_id: Date.now(), smry_cont: "" })
		setValue("content", updatedContents)
	}

	return (
		<MDBox py={2}>
			<MDBox display="flex" flexDirection="row" gap={2} alignItems="center">
				{`${index + 1}대지`}

				<Controller
					name={`content[${index}].sub_cont`}
					control={control}
					render={({ field }) => <MDInput {...field} sx={{ flexGrow: 1 }} />}
				/>

				<MDButton
					variant="gradient"
					color="secondary"
					size="medium"
					onClick={addSummaryHandler}
					iconOnly
				>
					+
				</MDButton>

				<MDButton
					variant="gradient"
					color="error"
					size="medium"
					onClick={removeFormHandler}
					sx={{ visibility: index === 0 ? "hidden" : "visible" }}
				>
					대지 삭제
				</MDButton>
			</MDBox>

			<SermonContentsSummary index={index} />
		</MDBox>
	)
}

const SermonContentsSummary = ({ index }) => {
	const { control, watch, setValue, getValues, trigger } = useFormContext()

	const watchSummary = watch(`content[${index}].summary`) || []

	const removeSummaryHandler = (id) => {
		const watchSummary = getValues(`content[${index}].summary`)
		const updatedSummary = watchSummary.filter(({ smry_id }, i) => id !== smry_id)
		setValue(`content[${index}].summary`, updatedSummary)
		trigger(`content[${index}].summary`)
	}

	return (
		<MDBox pb={2}>
			{watchSummary.map(({ smry_id }, summaryIndex) => (
				<React.Fragment key={`summary-${smry_id}`}>
					<MDBox display="flex" flexDirection="row" gap={2} alignItems="center" pt={1}>
						<MDTypography variant="subtitle2">{`${index + 1}대지 요약 ${summaryIndex + 1}`}</MDTypography>
						<Controller
							name={`content[${index}].summary[${summaryIndex}.smry_cont`}
							control={control}
							render={({ field }) => <MDInput {...field} sx={{ flexGrow: 1 }} />}
						/>

						<MDButton
							variant="gradient"
							color="error"
							size="medium"
							onClick={() => removeSummaryHandler(smry_id)}
							iconOnly
						>
							-
						</MDButton>
					</MDBox>
				</React.Fragment>
			))}
		</MDBox>
	)
}

const getFirstErrorMessage = (errors) => {
	const allErrorMessages = flatMap(errors.content, (error) => {
		return [
			get(error, "sub_cont.message"),
			...flatMap(get(error, "summary", []), (summaryError) =>
				get(summaryError, "smry_cont.message")
			),
		]
	})

	const firstErrorMessage = find(compact(allErrorMessages))

	return firstErrorMessage
}

export default SermonContents
