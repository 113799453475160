/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
// Material Dashboard 2 React layouts
// @mui icons
import Icon from "@mui/material/Icon"
import { getScreenName } from "assets/utils/screens"
import AttendanceScreen from "layouts/attendance"
import SignIn from "layouts/authentication/sign-in"
import SignUp from "layouts/authentication/sign-up"
import Billing from "layouts/billing"
import Dashboard from "layouts/dashboard"
import Notifications from "layouts/notifications"
import PraysScreen from "layouts/prays"
import Profile from "layouts/profile"
import ProgramsScreen from "layouts/programs"
import RTL from "layouts/rtl"
import SermonScreen from "layouts/sermon"
import SermonDetail from "layouts/sermon/[detail]"
import { SermonEdit, SermonWrite } from "layouts/sermon/edit"
import Tables from "layouts/tables"
import {
	ROUTE_ATEENDANCE,
	ROUTE_LOGIN,
	ROUTE_PRAYS,
	ROUTE_PROGRAMS,
	ROUTE_SERMON,
	ROUTE_SERMON_DETAIL,
	ROUTE_SERMON_MODIFY,
	ROUTE_SERMON_WRITE,
} from "urls"

const routes = [
	// {
	// 	type: "collapse",
	// 	name: "Dashboard",
	// 	key: "dashboard",
	// 	icon: <Icon fontSize="small">dashboard</Icon>,
	// 	route: "/dashboard",
	// 	component: <Dashboard />,
	// },
	{
		type: "collapse",
		name: getScreenName(ROUTE_ATEENDANCE),
		key: "attendance",
		icon: <Icon fontSize="small">border_color</Icon>,
		route: ROUTE_ATEENDANCE,
		component: <AttendanceScreen />,
	},
	{
		type: "collapse",
		name: getScreenName(ROUTE_PRAYS),
		key: "prays",
		icon: <Icon fontSize="small">list</Icon>,
		route: ROUTE_PRAYS,
		component: <PraysScreen />,
	},
	{
		type: "collapse",
		name: getScreenName(ROUTE_SERMON),
		key: "sermon",
		icon: <Icon fontSize="small">menu_book</Icon>,
		route: ROUTE_SERMON,
		collapse: [
			{
				name: getScreenName(ROUTE_SERMON),
				key: "sermon-root",
				route: ROUTE_SERMON,
				component: <SermonScreen />,
			},
			{
				name: getScreenName(ROUTE_SERMON_DETAIL),
				key: "sermon-detail",
				route: ROUTE_SERMON_DETAIL,
				component: <SermonDetail />,
			},
			{
				name: getScreenName(ROUTE_SERMON_WRITE),
				key: "sermon-write",
				route: ROUTE_SERMON_WRITE,
				component: <SermonWrite />,
			},
			{
				name: getScreenName(ROUTE_SERMON_MODIFY),
				key: "sermon-modify",
				route: ROUTE_SERMON_MODIFY,
				component: <SermonEdit />,
			},
		],
	},
	// {
	// 	type: "collapse",
	// 	name: getScreenName(ROUTE_SERMONS),
	// 	key: "sermons",
	// 	icon: <Icon fontSize="small">list</Icon>,
	// 	route: ROUTE_SERMONS,
	// 	component: <PraysScreen />,
	// },
	{
		type: "collapse",
		name: getScreenName(ROUTE_PROGRAMS),
		key: "programs",
		icon: <Icon fontSize="small">post_add</Icon>,
		route: ROUTE_PROGRAMS,
		component: <ProgramsScreen />,
	},
	// {
	// 	type: "collapse",
	// 	name: "Tables",
	// 	key: "tables",
	// 	icon: <Icon fontSize="small">table_view</Icon>,
	// 	route: "/tables",
	// 	component: <Tables />,
	// },
	// {
	// 	type: "collapse",
	// 	name: "Billing",
	// 	key: "billing",
	// 	icon: <Icon fontSize="small">receipt_long</Icon>,
	// 	route: "/billing",
	// 	component: <Billing />,
	// },
	// {
	// 	type: "collapse",
	// 	name: "RTL",
	// 	key: "rtl",
	// 	icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
	// 	route: "/rtl",
	// 	component: <RTL />,
	// },
	// {
	// 	type: "collapse",
	// 	name: "Notifications",
	// 	key: "notifications",
	// 	icon: <Icon fontSize="small">notifications</Icon>,
	// 	route: "/notifications",
	// 	component: <Notifications />,
	// },
	// {
	// 	type: "collapse",
	// 	name: "Profile",
	// 	key: "profile",
	// 	icon: <Icon fontSize="small">person</Icon>,
	// 	route: "/profile",
	// 	component: <Profile />,
	// },
	// {
	// 	type: "collapse",
	// 	name: "Sign Up",
	// 	key: "sign-up",
	// 	icon: <Icon fontSize="small">assignment</Icon>,
	// 	route: "/authentication/sign-up",
	// 	component: <SignUp />,
	// },
]

export default routes
