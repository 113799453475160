import { createApi } from "@reduxjs/toolkit/query/react"
import { removeUser } from "stores/slice/user"

import customAxios from "./customAxios"
import * as Tags from "./tags"

const UNAUTHORIZED_STATUSES = [401, 403]

export const api = (reducerPath, ...options) =>
	createApi({
		reducerPath,
		baseQuery: async (args, { dispatch }) => {
			try {
				const { data } = await customAxios(args)

				return { data }
			} catch (error) {
				const status = error.response?.status

				if (UNAUTHORIZED_STATUSES.includes(status)) {
					dispatch(removeUser())
				}

				return {
					error: {
						...error.response.data,
						message: error.response.data?.message || "Something went wrong.",
					},
				}
			}
		},
		tagTypes: Object.values(Tags),
		endpoints: () => ({}),
		...options,
	})

export const commonApi = api("common")
