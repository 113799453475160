import PropTypes from "prop-types"
import React, { useState } from "react"

// Correctly import the mutation hook

const UploadImage = ({ image, onImageClick }) => {
	return (
		<div style={{ textAlign: "center" }}>
			<div style={{ display: "flex", justifyContent: "center" }} onClick={onImageClick}>
				<img
					src={image}
					alt="Upload Preview"
					style={{
						width: "30%",
						height: "auto",
						marginTop: "20px",
						marginBottom: "20px",
						borderRadius: "50px",
						cursor: "pointer",
					}}
				/>
			</div>
			<p style={{ fontSize: "30px", fontWeight: "bold" }}>{"클릭해서 주보를 업로드해주세요"}</p>
		</div>
	)
}

UploadImage.propTypes = {
	image: PropTypes.string.isRequired,
	onImageClick: PropTypes.func,
}

export default UploadImage
