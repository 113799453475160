/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Divider from "@mui/material/Divider"
import Icon from "@mui/material/Icon"
import Link from "@mui/material/Link"
// @mui material components
import List from "@mui/material/List"
// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
// Material Dashboard 2 React context
import {
	setMiniSidenav,
	setTransparentSidenav,
	setWhiteSidenav,
	useMaterialUIController,
} from "context"
// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse"
// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot"
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav"
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
// react-router-dom components
import { NavLink, useLocation } from "react-router-dom"
import { openSnackbar } from "stores/slice/snackbar"
import { removeUser } from "stores/slice/user"

function Sidenav({ color, brand, brandName, routes, ...rest }) {
	const [controller, dispatch] = useMaterialUIController()
	const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller
	const location = useLocation()
	const collapseName = location.pathname.split("/")[1]
	const storeDispatch = useDispatch()

	let textColor = "white"

	if (transparentSidenav || (whiteSidenav && !darkMode)) {
		textColor = "dark"
	} else if (whiteSidenav && darkMode) {
		textColor = "inherit"
	}

	const closeSidenav = () => setMiniSidenav(dispatch, true)

	useEffect(() => {
		// A function that sets the mini state of the sidenav.
		function handleMiniSidenav() {
			setMiniSidenav(dispatch, window.innerWidth < 1200)
			setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav)
			setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav)
		}

		/** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
		window.addEventListener("resize", handleMiniSidenav)

		// Call the handleMiniSidenav function to set the state with the initial value.
		handleMiniSidenav()

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleMiniSidenav)
	}, [dispatch, location])

	// Render all the routes from the routes.js (All the visible items on the Sidenav)
	const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
		let returnValue

		if (type === "collapse") {
			returnValue = href ? (
				<Link
					href={href}
					key={key}
					target="_blank"
					rel="noreferrer"
					sx={{ textDecoration: "none" }}
				>
					<SidenavCollapse
						name={name}
						icon={icon}
						active={key === collapseName}
						noCollapse={noCollapse}
					/>
				</Link>
			) : (
				<NavLink key={key} to={route}>
					<SidenavCollapse name={name} icon={icon} active={key === collapseName} />
				</NavLink>
			)
		} else if (type === "title") {
			returnValue = (
				<MDTypography
					key={key}
					color={textColor}
					display="block"
					variant="caption"
					fontWeight="bold"
					textTransform="uppercase"
					pl={3}
					mt={2}
					mb={1}
					ml={1}
				>
					{title}
				</MDTypography>
			)
		} else if (type === "divider") {
			returnValue = (
				<Divider
					key={key}
					light={
						(!darkMode && !whiteSidenav && !transparentSidenav) ||
						(darkMode && !transparentSidenav && whiteSidenav)
					}
				/>
			)
		}

		return returnValue
	})

	const onClickLogout = () => {
		try {
			storeDispatch(removeUser())
		} catch (error) {
			storeDispatch(openSnackbar({ severity: "error", message: "로그아웃에 실패했습니다" }))
		} finally {
			storeDispatch(openSnackbar({ severity: "info", message: "로그아웃 되었습니다" }))
		}
	}

	return (
		<SidenavRoot
			{...rest}
			variant="permanent"
			ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
		>
			<MDBox pt={3} pb={1} px={4} textAlign="center">
				<MDBox
					display={{ xs: "block", xl: "none" }}
					position="absolute"
					top={0}
					right={0}
					p={1.625}
					onClick={closeSidenav}
					sx={{ cursor: "pointer" }}
				>
					<MDTypography variant="h6" color="secondary">
						<Icon sx={{ fontWeight: "bold" }}>close</Icon>
					</MDTypography>
				</MDBox>
				<MDBox component={NavLink} to="/" display="flex" alignItems="center" gap={1}>
					{brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
					<MDBox
						width={!brandName && "100%"}
						sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
					>
						<MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
							{brandName}
						</MDTypography>
					</MDBox>
				</MDBox>
			</MDBox>
			<Divider
				light={
					(!darkMode && !whiteSidenav && !transparentSidenav) ||
					(darkMode && !transparentSidenav && whiteSidenav)
				}
			/>
			<List>{renderRoutes}</List>
			<MDBox p={2} mt="auto">
				<MDButton
					component="button"
					variant="gradient"
					color={sidenavColor}
					fullWidth
					onClick={onClickLogout}
				>
					로그아웃
				</MDButton>
			</MDBox>
		</SidenavRoot>
	)
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
	color: "info",
	brand: "",
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
	color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
	brand: PropTypes.string,
	brandName: PropTypes.string.isRequired,
	routes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Sidenav
