import { createSlice } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { APP_VERSION_SLICE } from "stores/sliceKeys"

/**
 * @typedef {Object} AppVersion
 * @property {string} appVersion
 */
const initialState = {
	appVersion: process.env.REACT_APP_VERSION,
}

const appVersionSlice = createSlice({
	name: APP_VERSION_SLICE,
	initialState,
	reducers: {
		updateAppVersion(state, action) {
			const { appVersion } = action.payload
			return { ...state, appVersion }
		},
	},
})

export const { updateAppVersion } = appVersionSlice.actions

export const useAppVersion = () => useSelector((state) => state[APP_VERSION_SLICE])

export default appVersionSlice.reducer
