import { Card, Grid } from "@mui/material"
import MDBadge from "components/MDBadge"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { showModal } from "components/Modal"
import { format } from "date-fns"
import DataTable from "examples/Tables/DataTable"
import { UserDetail } from "layouts/user/components"
import PropTypes from "prop-types"
import { useState } from "react"
import { getPreviousDay } from "util/date"

const AttendanceList = ({ data, watchedDate }) => {
	const columns = [
		{ Header: "프로필", accessor: "profile", width: "10%", align: "center", noPadding: true },
		{ Header: "이름", accessor: "name", width: "10%", align: "left" },
		{ Header: "직급", accessor: "type", align: "left" },
		{ Header: "교구", accessor: "parish_name", align: "center" },
		{ Header: "속회", accessor: "group_name", align: "center" },
		{
			Header: `${format(getPreviousDay(watchedDate, 28), "M/dd")} 출석`,
			accessor: "past_attendance4",
			align: "center",
		},
		{
			Header: `${format(getPreviousDay(watchedDate, 21), "M/dd")} 출석`,
			accessor: "past_attendance3",
			align: "center",
		},
		{
			Header: `${format(getPreviousDay(watchedDate, 14), "M/dd")} 출석`,
			accessor: "past_attendance2",
			align: "center",
		},
		{
			Header: `${format(getPreviousDay(watchedDate, 7), "M/dd")} 출석`,
			accessor: "past_attendance1",
			align: "center",
		},
		{
			Header: `${format(watchedDate, "M/dd")} 출석`,
			accessor: "past_attendance0",
			align: "center",
		},
		{
			Header: "유저정보 hide",
			accessor: "user_info",
			hidden: true,
		},
	]

	const rows = data.map(
		(
			{ attendance, parish_name, photo_url, group_name, name, parish_id, type, user_id },
			rowIndex
		) => {
			/**
			 * @param {"Y" | "N" | "U"} isAttended
			 */
			const getAttendanceBadge = (isAttended) => {
				const attendedState = isAttended === "Y" ? "success" : isAttended === "N" ? "error" : "dark"
				return (
					<MDBadge
						badgeContent={isAttended === "Y" ? "출석" : isAttended === "N" ? "결석" : "미정"}
						color={attendedState}
						variant="gradient"
						size="md"
					/>
				)
			}

			const attendanceArr = attendance.reduce((acc, { attended }, index) => {
				acc[`past_attendance${index}`] = getAttendanceBadge(attended)
				return acc
			}, [])

			return {
				profile: photo_url ? (
					<img src={photo_url} alt="" style={{ height: "40px", width: "auto" }} />
				) : (
					<></>
				),
				name,
				type,
				parish_name,
				group_name,
				...attendanceArr,
				user_info: data[rowIndex],
			}
		}
	)

	const onRowClick = ({ original }) => {
		if (!original) return

		const { user_info: userInfo } = original

		const { user_id, group_id } = userInfo
		showModal(({ closeModal }) => (
			<UserDetail userId={user_id} groupId={group_id} closeModal={closeModal} />
		))
	}

	return (
		<MDBox pt={6} pb={3}>
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<Card>
						<MDBox
							mx={2}
							mt={-3}
							py={3}
							px={2}
							variant="gradient"
							bgColor="info"
							borderRadius="lg"
							coloredShadow="info"
						>
							<MDTypography variant="h6" color="white">
								출석부
							</MDTypography>
						</MDBox>
						<MDBox pt={3}>
							<DataTable table={{ columns, rows }} isSorted={false} onRowClick={onRowClick} />
						</MDBox>
					</Card>
				</Grid>
			</Grid>
		</MDBox>
	)
}

export default AttendanceList

AttendanceList.propTypes = {
	data: PropTypes.array.isRequired,
	watchedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
}
