import { Box, Button, CircularProgress, Icon, Typography, useTheme } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3"
import { DATE_FORMAT } from "constant"
import { format, parse } from "date-fns"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import useFirebaseApp from "hooks/useFirebaseApp"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { adminApi } from "services/modules"
import { openSnackbar } from "stores/slice/snackbar"

export default function UserDetail({ userId, groupId, closeModal, setProfileUrl }) {
	const dispatch = useDispatch()
	const inputRef = React.createRef()
	const [birthday, setBirthday] = useState(null)
	const [uploading, setUploading] = useState(false)
	const [imageURL, setImageURL] = useState("")
	const { shadows } = useTheme()

	const { getFirebaseStorage } = useFirebaseApp()

	const { data, isSuccess } = adminApi.useGetAttendanceUserQuery({
		user_id: userId,
		group_id: groupId,
	})

	const [trigger] = adminApi.usePostAttendanceChangeProfileMutation()

	const {
		group_name,
		parish_name,
		type,
		name,
		profile_url,
		birth_date: defaultBirthday,
	} = data?.data || {}

	useEffect(() => {
		if (isSuccess && profile_url) {
			setImageURL(profile_url)
		}
	}, [isSuccess])

	useEffect(() => {
		const fetchUserBirthday = async () => {
			try {
				if (!birthday) return

				const parsedDate = format(birthday, DATE_FORMAT)

				const res = await trigger({
					birth_date: parsedDate,
					user_id: userId,
				}).unwrap()

				if (!res.error) {
					dispatch(
						openSnackbar({ severity: "info", message: res.data || "생일이 변경되었습니다." })
					)
				}
			} catch (error) {
				console.error(error)
			}
		}

		fetchUserBirthday()
	}, [birthday])

	// eslint-disable-next-line react/prop-types
	const InfoBlock = ({ label, value }) => (
		<Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
			<Typography variant="subtitle1" fontWeight="800" fontSize="14px" textAlign="center">
				{label}
			</Typography>
			<Typography color="secondary" fontSize="17px" textAlign="center">
				{value}
			</Typography>
		</Box>
	)

	const onChangeFileInput = (e) => {
		const file = e.target.files[0]
		if (!file) return

		storeImageOnFirebaseStorage(file)
	}

	const storeImageOnFirebaseStorage = (file) => {
		setUploading(true)
		const storage = getFirebaseStorage()
		const storageRef = ref(storage, `profile/${userId}`)
		uploadBytesResumable(storageRef, file)
			.then((snapshot) => {
				getDownloadURL(storageRef)
					.then((url) => {
						setImageURL(url)
						return url
					})
					.then(async (url) => {
						await trigger({ user_id: userId, photo_url: url }).unwrap()
						return url
					})
					.then((url) => {
						typeof setProfileUrl === "function" && setProfileUrl(url)
					})
			})
			.catch((error) => {
				console.error("error", error)
			})
			.finally(() => {
				setUploading(false)
			})
	}

	return (
		<Box
			sx={{
				px: 3,
				py: 5,
				borderRadius: "32px",
				backgroundColor: "white",
				width: "360px",
				boxShadow: "8px 16px 32px 0px #004A6729;",
			}}
		>
			<Box sx={{ position: "relative", textAlign: "center", pb: 2 }}>
				<Typography sx={{ fontSize: "32px", lineHeight: "32px", fontWeight: "900" }}>
					유저 상세정보
				</Typography>
				<Box
					sx={{
						position: "absolute",
						top: "0",
						right: "0",
						cursor: "pointer",
						fontWeight: "900",
					}}
					onClick={closeModal}
				>
					<Icon>close</Icon>
				</Box>
			</Box>
			<Box
				sx={{
					color: "#FFFFFF",
					backgroundColor: "white",
					boxShadow: shadows[2],
					color: "black",
					position: "relative",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					margin: "0 auto",
					width: "96px",
					height: "96px",
					borderRadius: "32px",
					"&>img": { width: "96px", height: "96px" },
				}}
			>
				{imageURL ? (
					<img
						src={imageURL}
						alt={name}
						style={{ borderRadius: "0.75rem", maxHeight: "96px", width: "auto" }}
					/>
				) : (
					<Icon fontSize="large" sx={{ width: "100%", height: "auto" }}>
						person
					</Icon>
				)}
				<input
					type="file"
					style={{ display: "none" }}
					ref={inputRef}
					onChange={onChangeFileInput}
				/>
			</Box>

			<Box
				sx={{
					margin: "0 auto",
					mt: 1,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					color="secondary"
					size="small"
					onClick={() => inputRef.current.click()}
					sx={{ minWidth: "120px" }}
					disabled={uploading}
				>
					{uploading ? <CircularProgress size={20} color="inherit" /> : "프로필사진 변경"}
				</Button>
			</Box>

			<Box mt={3} display="flex">
				<InfoBlock label="이름" value={name} />
				<InfoBlock label="직급" value={type} />
			</Box>

			<Box mt={3} display="flex">
				<InfoBlock label="교구" value={parish_name} />
				<InfoBlock label="속장" value={group_name} />
			</Box>

			<div style={{ display: "flex", justifyContent: "center", paddingTop: "1rem" }}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<MuiDatePicker
						label="생일을 선택해주세요"
						value={defaultBirthday ? new Date(defaultBirthday) : birthday}
						onChange={(date) => setBirthday(date)}
						format={DATE_FORMAT}
						slotProps={{
							textField: {
								onKeyDown: (e) => {
									e.preventDefault()
								},
							},
						}}
						disableFuture
					/>
				</LocalizationProvider>
			</div>
		</Box>
	)
}

UserDetail.propTypes = {
	closeModal: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
	groupId: PropTypes.string.isRequired,
	setProfileUrl: PropTypes.func,
}
