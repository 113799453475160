import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import SimpleDialog from "components/Dialog"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import DataTable from "examples/Tables/DataTable"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { adminApi } from "services/modules"
import { openSnackbar } from "stores/slice/snackbar"
import { ROUTE_SERMON, ROUTE_SERMON_WRITE } from "urls"

const SermonScreen = () => {
	const [open, setOpen] = useState(false)
	const [rows, setRows] = useState([])
	const [selectedRow, setSelectedRow] = useState(null)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const columns = useMemo(() => {
		return [
			{
				Header: "설교 제목",
				accessor: "title",
				width: "*",
				align: "left",
			},
			{
				Header: "말씀 본문",
				accessor: "bible_verse",
				width: "30%",
				align: "left",
			},
			{
				Header: "설교자",
				accessor: "preacher_nm",
				width: "15%",
				align: "center",
			},
			{
				Header: "날짜",
				accessor: "weekly_date",
				sortable: true,
				align: "center",
				width: "13%",
			},
			{
				Header: "삭제",
				accessor: "actions",
				right: false,
				width: "9%",
			},
		]
	}, [])

	const [lazyRowsTrigger] = adminApi.useLazyGetSermonListQuery()
	const [deleteSermonTrigger] = adminApi.useDeleteSermonSummaryMutation()

	useEffect(() => {
		const fetchData = async () => {
			let { data } = await lazyRowsTrigger().unwrap()
			data = data.map((el) => ({
				...el,
				actions: (
					<DeleteOutlineIcon
						color="error"
						fontSize="small"
						onClick={(e) => {
							e.stopPropagation()
							e.preventDefault()
							setOpen(true)
							setSelectedRow(el)
						}}
						sx={{
							cursor: "pointer",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						Delete
					</DeleteOutlineIcon>
				),
			}))
			setRows(data)
		}

		fetchData()
	}, [lazyRowsTrigger])

	const deleteSermonHandler = useCallback(async () => {
		if (!selectedRow) return

		try {
			const { data: message } = await deleteSermonTrigger({
				srmn_id: selectedRow.sermon_id,
			}).unwrap()
			dispatch(openSnackbar({ message }))
			const data = rows.filter((el) => el.sermon_id !== selectedRow.sermon_id)
			setRows(data)
			setSelectedRow(null)
		} catch (error) {
			dispatch(
				openSnackbar({
					severity: "error",
					message: error?.message || "오류가 발생했습니다.",
				})
			)
		} finally {
			setOpen(false)
		}
	}, [rows, selectedRow])

	const onRowClick = useCallback(
		({ original }) => {
			if (!original) return

			navigate(`${ROUTE_SERMON}/${original.sermon_id}`, {
				state: { weekly_date: original.weekly_date },
			})
		},
		[navigate]
	)

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pb={3}>
				<MDBox position="relative">
					<DataTable
						table={{ columns, rows }}
						isSorted={false}
						entriesPerPage={false}
						showTotalEntries={false}
						onRowClick={onRowClick}
					/>
				</MDBox>

				<MDBox display="flex" justifyContent="flex-end" mt={3}>
					<MDButton color="primary" onClick={() => navigate(ROUTE_SERMON_WRITE)}>
						새 설교 작성하기
					</MDButton>
				</MDBox>
			</MDBox>
			<SimpleDialog
				open={open}
				onClose={() => setOpen(false)}
				onSuccess={deleteSermonHandler}
				message={`${selectedRow?.title} (${selectedRow?.weekly_date}) 설교를 삭제하시겠습니까?`}
			/>
		</DashboardLayout>
	)
}

export default SermonScreen
