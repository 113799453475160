import { Backdrop, CircularProgress } from "@mui/material"

const OverlayProgress = () => {
	return (
		<Backdrop sx={{ color: "#00369F", zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
			<CircularProgress color="inherit" />
		</Backdrop>
	)
}

export default OverlayProgress
