import { Box, Modal } from "@mui/material"
import AppWrapper from "AppWrapper"
import { createRoot } from "react-dom/client"

export function showModal(Component) {
	const container = document.getElementById("modal-app")
	const root = createRoot(container)

	const handleCloseModal = () => {
		root.unmount()
	}

	return root.render(
		<AppWrapper>
			<Modal open>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						":focus-visible": {
							outline: "none",
						},
					}}
				>
					<Component closeModal={handleCloseModal} />
				</Box>
			</Modal>
		</AppWrapper>
	)
}
