/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 React Context Provider
import AppWrapper from "AppWrapper"
import React from "react"
import { createRoot } from "react-dom/client"

import App from "./App"

const container = document.getElementById("app")
const root = createRoot(container)

root.render(
	<AppWrapper>
		<App />
	</AppWrapper>
)
