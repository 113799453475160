import PropTypes from "prop-types"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { homeApi } from "services/modules"
import { openSnackbar } from "stores/slice/snackbar"

import ImageWithControls from "./ImageWithControls"

// Import the new component

const ScrollableImageList = ({ images, setImages, weeklyStr }) => {
	const dispatch = useDispatch()
	// Function to handle deleting an image
	const handleDelete = (indexToDelete) => {
		const image_id = images[indexToDelete].image_id
		const newImages = images.filter((_, index) => index !== indexToDelete)
		trigger_delete({ data: { weekly_date: weeklyStr, image_id: image_id } })
		dispatch(
			openSnackbar({
				severity: "success",
				message: "이미지가 성공적으로 삭제되었습니다.",
			})
		)
		updateImageOrders(newImages)
	}

	// Function to handle moving an image to the left
	const handleMoveLeft = (index) => {
		if (index > 0) {
			const newImages = [...images]
			;[newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]]
			const updatedImages = updateImageOrders(newImages)
			trigger_update({ data: { weekly_date: weeklyStr, images: updatedImages } })
			dispatch(
				openSnackbar({
					severity: "success",
					message: "이미지 순서가 바뀌었습니다",
				})
			)
		}
	}

	// Function to handle moving an image to the right
	const handleMoveRight = (index) => {
		if (index < images.length - 1) {
			const newImages = [...images]
			;[newImages[index + 1], newImages[index]] = [newImages[index], newImages[index + 1]]
			const updatedImages = updateImageOrders(newImages)
			trigger_update({ data: { weekly_date: weeklyStr, images: updatedImages } })
			dispatch(
				openSnackbar({
					severity: "success",
					message: "이미지 순서가 바뀌었습니다",
				})
			)
		}
	}

	// Function to update the order field for each image based on its position
	const updateImageOrders = (newImages) => {
		// Reassign the order based on the new position
		const updatedImages = newImages.map((img, idx) => ({
			...img,
			order: idx + 1, // Assuming order starts from 1
		}))

		setImages(updatedImages)
		return updatedImages
	}

	const [trigger_update] = homeApi.useUpdateProgramImageOrderMutation()
	const [trigger_delete] = homeApi.useDeleteProgramImageMutation()

	return (
		<div
			style={{
				overflowX: "scroll",
				whiteSpace: "nowrap",
				padding: "5px 0",
				display: "flex",
				gap: "20px",
			}}
		>
			{images.map((img, index) => (
				<ImageWithControls
					key={index}
					img={img}
					index={index}
					onDelete={handleDelete}
					onMoveLeft={handleMoveLeft}
					onMoveRight={handleMoveRight}
					isLast={index === images.length - 1}
				/>
			))}
		</div>
	)
}

// Define PropTypes
ScrollableImageList.propTypes = {
	images: PropTypes.arrayOf(PropTypes.object).isRequired,
	setImages: PropTypes.func.isRequired,
	weeklyStr: PropTypes.string.isRequired,
}

export default ScrollableImageList
