import { api } from "services/api"
import {
	API_PROGRAM_CHANGE_ORDER,
	API_PROGRAM_DELETE_IMAGE,
	API_PROGRAM_GET_IMAGES,
	API_PROGRAM_UPLOAD_IMAGES,
} from "services/endpoints"

export default api("homeAPI").injectEndpoints({
	endpoints: (build) => ({
		getProgramImage: build.query({
			query({ weekly_str }) {
				return {
					url: API_PROGRAM_GET_IMAGES,
					params: { weekly_date: weekly_str },
				}
			},
			transformResponse(response) {
				return response.data
			},
			providesTags: ["ProgramImages"],
		}),
		postUploadProgramImage: build.mutation({
			query({ weekly_str, files }) {
				const formData = new FormData()
				console.log(weekly_str)
				formData.append("weekly_date", weekly_str)
				for (let i = 0; i < files.length; i++) {
					formData.append("files", files[i])
				}

				return {
					url: API_PROGRAM_UPLOAD_IMAGES,
					method: "POST",
					data: formData,
					headers: {
						"Content-Type": "multipart/form-data;",
					},
					formData: true,
				}
			},
		}),
		updateProgramImageOrder: build.mutation({
			query({ data }) {
				console.log(data)
				return {
					url: API_PROGRAM_CHANGE_ORDER,
					data: data,
					method: "PUT",
				}
			},
			invalidatesTags: ["ProgramImages"],
		}),
		deleteProgramImage: build.mutation({
			query({ data }) {
				return {
					url: API_PROGRAM_DELETE_IMAGE,
					data,
					method: "DELETE",
				}
			},
			invalidatesTags: ["ProgramImages"],
		}),
	}),
})
