import { DATE_FORMAT } from "constant"
import { format } from "date-fns"
import { get, isEmpty, map, omitBy } from "lodash"
import { api } from "services/api"
import {
	API_ADMIN_ATTENDANCE,
	API_ADMIN_HIERARCHY,
	API_ADMIN_PRAYERS,
	API_ADMIN_UPDATE_USER,
	API_ADMIN_USER,
	API_ATTENDANCE_UNCHECKED,
	API_SERMON_LIST,
	API_SERMON_SUMMARY,
} from "services/endpoints"
import {
	TAG_ATTENDANCE_LIST,
	TAG_PARISHES,
	TAG_PRAYERS,
	TAG_SERMON_DETAIL,
	TAG_SERMON_LIST,
	TAG_USER,
} from "services/tags"

export default api("adminApi").injectEndpoints({
	endpoints: (build) => ({
		getParishesHirarchy: build.query({
			query({ id }) {
				return {
					url: API_ADMIN_HIERARCHY,
					params: { user_id: id },
				}
			},

			providesTags: [TAG_PARISHES],
		}),

		getAttendanceList: build.query({
			query(data) {
				const params = toQueryString(data)
				return {
					data,
					url: `${API_ADMIN_ATTENDANCE}?${params}`,
				}
			},

			providesTags: [TAG_ATTENDANCE_LIST],
		}),

		postAttendanceChangeProfile: build.mutation({
			query(data) {
				return {
					method: "PATCH",
					data,
					url: API_ADMIN_UPDATE_USER,
				}
			},

			invalidatesTags: [TAG_USER, TAG_ATTENDANCE_LIST],
		}),

		getAttendanceUser: build.query({
			query(data) {
				const params = toQueryString(data)
				return {
					method: "GET",
					url: `${API_ADMIN_USER}?${params}`,
				}
			},

			providesTags: [TAG_USER],
		}),

		getPrayers: build.query({
			query(data) {
				const params = toQueryString(data)
				return {
					data,
					url: `${API_ADMIN_PRAYERS}?${params}`,
				}
			},

			transformResponse: (response) => {
				const data = response?.data || {}

				const { groups } = data
				if (isEmpty(groups)) return {}

				return omitBy(groups, (group) => isEmpty(group?.prayer_lists))
			},

			providesTags: [TAG_PRAYERS],
		}),

		getAttendanceUnchecked: build.query({
			/**
			 * @param {{weekly_date: string; parish_id?: string;}} data
			 */
			query(data) {
				const params = toQueryString(data)
				return {
					url: `${API_ATTENDANCE_UNCHECKED}?${params}`,
				}
			},
		}),

		getSermonList: build.query({
			query() {
				return {
					url: API_SERMON_LIST,
				}
			},
			providesTags: [TAG_SERMON_LIST],
		}),

		getSermonSummaryDetail: build.query({
			/** @param {{weekly_date: string}} data */
			query(data) {
				const params = toQueryString(data)
				return {
					url: `${API_SERMON_SUMMARY}?${params}`,
				}
			},

			transformResponse: (response) => {
				const data = response?.data || {}
				const { weekly_date, ...rest } = data

				response.data = {
					...rest,
					weekly_date: new Date(weekly_date),
				}

				return response
			},

			providesTags: [TAG_SERMON_DETAIL],
		}),

		postSermonSummary: build.mutation({
			/**
			 * @param {{
			 * 		weekly_date: string;
			 * 		title: string;
			 * 		bible_verse: string;
			 * 		content: {sub_cont: string; summary: {smry_cont: string}[]}[];
			 * }} data
			 */
			query(data) {
				data.weekly_date = format(data.weekly_date, DATE_FORMAT)
				data.content = map(data.content, (item) => {
					const summary = isEmpty(item.summary)
						? []
						: map(item.summary, (smry) => get(smry, "smry_cont", ""))
					return {
						sub_cont: get(item, "sub_cont", ""),
						summary,
					}
				})
				return {
					method: "POST",
					data,
					url: API_SERMON_SUMMARY,
				}
			},
		}),

		putSermonSummary: build.mutation({
			/**
			 * @param {{
			 * 		weekly_date: string;
			 * 		title: string;
			 * 		bible_verse: string;
			 * 		content: {sub_id?: string; id?: string; sub_order?: number; sub_cont?: string; summary: {smry_cont: string}[]}[];
			 * }} data
			 */
			query(data) {
				data.weekly_date = format(data.weekly_date, DATE_FORMAT)
				data.content = map(data.content, (item) => {
					const summary = isEmpty(item.summary)
						? []
						: map(item.summary, (smry) => get(smry, "smry_cont", ""))
					return {
						sub_cont: get(item, "sub_cont", ""),
						summary,
					}
				})
				return {
					method: "PUT",
					data,
					url: API_SERMON_SUMMARY,
				}
			},

			invalidatesTags: [TAG_SERMON_DETAIL],
		}),

		deleteSermonSummary: build.mutation({
			/**
			 * @param {{srmn_id: string}} data
			 */
			query(data) {
				const params = toQueryString(data)
				return {
					method: "DELETE",
					url: `${API_SERMON_SUMMARY}?${params}`,
				}
			},

			invalidatesTags: [TAG_SERMON_DETAIL],
		}),
	}),
})

//
// HELPERS
//
const toQueryString = (params) => {
	return Object.keys(params)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
		.join("&")
}
