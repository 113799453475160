/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
// @mui material components
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { IconButton } from "@mui/material"
import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import Icon from "@mui/material/Icon"
// Material Dashboard 2 React components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { UncheckedGroupModal } from "layouts/attendance/components"
import PropTypes from "prop-types"
import { useState } from "react"

function ComplexStatisticsCard({ color, title, count, percentage, icon, isUnchecked = false }) {
	const [open, setOpen] = useState(false)
	return (
		<Card>
			<MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
				<MDBox
					variant="gradient"
					bgColor={color}
					color={color === "light" ? "dark" : "white"}
					coloredShadow={color}
					borderRadius="xl"
					display="flex"
					justifyContent="center"
					alignItems="center"
					width="4rem"
					height="4rem"
					mt={-3}
				>
					<Icon fontSize="medium" color="inherit">
						{icon}
					</Icon>
				</MDBox>
				<MDBox textAlign="right" lineHeight={1.25}>
					<MDBox display="flex" alignItems="center">
						<MDTypography variant="button" fontWeight="medium" color="text">
							{title}
						</MDTypography>
					</MDBox>
					<MDTypography variant="h4">{count}</MDTypography>
				</MDBox>
			</MDBox>
			<Divider />
			<MDBox pb={2} px={2} display="flex" flexDirection="row-reverse">
				{isUnchecked ? (
					<MDBox display="flex" alignItems="center">
						<IconButton aria-label="uncheckedGroup" onClick={() => setOpen(true)} sx={{ p: 0 }}>
							<MDTypography component="p" variant="button" color="text" display="flex">
								출석 미확인 속회
							</MDTypography>
							<ChevronRightIcon fontSize="small" />
						</IconButton>
						<UncheckedGroupModal open={open} onClose={() => setOpen(false)} />
					</MDBox>
				) : (
					<MDTypography component="p" variant="button" color="text" display="flex">
						{percentage.label}&nbsp;
						<MDTypography
							component="span"
							variant="button"
							fontWeight="bold"
							color={percentage.color}
						>
							{percentage.amount}
						</MDTypography>
					</MDTypography>
				)}
			</MDBox>
		</Card>
	)
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
	color: "info",
	percentage: {
		color: "success",
		text: "",
		label: "",
	},
}

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
	color: PropTypes.oneOf([
		"primary",
		"secondary",
		"info",
		"success",
		"warning",
		"error",
		"light",
		"dark",
	]),
	title: PropTypes.string.isRequired,
	count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	percentage: PropTypes.shape({
		color: PropTypes.oneOf([
			"primary",
			"secondary",
			"info",
			"success",
			"warning",
			"error",
			"dark",
			"white",
		]),
		amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		label: PropTypes.string,
	}),
	icon: PropTypes.node.isRequired,
	isUnchecked: PropTypes.bool,
}

export default ComplexStatisticsCard
