import MDBox from "components/MDBox"
import MDSnackbar from "components/MDSnackbar"
import OverlayProgress from "components/OverlayProgress"
import { DATE_FORMAT } from "constant"
import { format } from "date-fns"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import Footer from "layouts/authentication/components/Footer"
import ParishFilter from "layouts/common/ParishFilter"
import { isEmpty, omitBy } from "lodash"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { adminApi } from "services/modules"
import { useUser } from "stores/slice/user"
import { getNearestSunday } from "util/date"

import { PraysList } from "./components"

const PraysScreen = () => {
	const { id } = useUser()
	const { watch, control, resetField } = useForm({
		defaultValues: {
			date: getNearestSunday(),
			parish: "",
			group: "",
		},
	})

	const { date: watchedDate, parish: watchedParish, group: watchedGroup } = watch()
	const weeklyStr = useMemo(
		() => (watchedDate ? format(watchedDate, DATE_FORMAT) : ""),
		[watchedDate]
	)
	const memoizedData = useMemo(() => {
		const data = {
			weekly_str: weeklyStr,
			parish_id: watchedParish,
			group_id: watchedGroup,
		}
		return omitBy(data, isEmpty)
	}, [weeklyStr, watchedParish, watchedGroup])

	const {
		data = {},
		isFetching,
		isLoading,
		error,
		isError,
	} = adminApi.useGetPrayersQuery(memoizedData, {
		skip: !watchedParish,
	})

	if (isError) {
		return (
			<MDSnackbar
				color="error"
				icon="error"
				title="데이터를 불러오는 중 오류가 발생했습니다."
				content={error?.message}
				open={isError}
				onClose={() => {}}
				close={() => {}}
				bgWhite
			/>
		)
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pb={3}>
				<ParishFilter control={control} watch={watch} resetField={resetField} />

				<MDBox position="relative">
					{/* <MDButton
						variant="contained"
						color="primary"
						onClick={() => alert("pdf 다운로드 기능은 준비중입니다.")}
						sx={{
							position: "absolute",
							top: 0,
							right: { xs: 0, lg: 30 },
						}}
						disabled={isLoading || isFetching}
					>
						Pdf 다운로드
					</MDButton> */}

					{isLoading || isFetching ? (
						<OverlayProgress />
					) : (
						<PraysList data={data} parish={watchedParish} />
					)}
				</MDBox>
			</MDBox>
			<Footer />
		</DashboardLayout>
	)
}

export default PraysScreen
