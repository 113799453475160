import { FormControl } from "@mui/material"
import DatePicker from "components/DatePicker"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import { DATE_FORMAT } from "constant"
import { format } from "date-fns"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import Footer from "layouts/authentication/components/Footer"
import { useMemo } from "react"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { homeApi } from "services/modules"
import { openSnackbar } from "stores/slice/snackbar"
import { getNearestSunday } from "util/date"

import image from "../../assets/images/upload_images.png"
import { ScrollableImageList, UploadImage } from "./components"

const ProgramScreen = () => {
	const dispatch = useDispatch()
	const form = useForm({
		defaultValues: {
			date: getNearestSunday(),
		},
	})
	const { watch, control } = form

	const { date: watchedDate } = watch()
	const weeklyStr = useMemo(
		() => (watchedDate ? format(watchedDate, DATE_FORMAT) : ""),
		[watchedDate]
	)

	// Image list related components and functions

	const [imageList, setImageList] = useState([])
	const [postUploadProgramImage] = homeApi.usePostUploadProgramImageMutation()
	const {
		data = {},
		isSuccess,
		refetch,
	} = homeApi.useGetProgramImageQuery({ weekly_str: weeklyStr })

	useEffect(() => {
		console.log(data)
		if (isSuccess && data) {
			setImageList(data)
		}
	}, [isSuccess, data, setImageList])

	const imageUploadHandler = () => {
		const input = document.createElement("input")
		input.type = "file"
		input.accept = "image/*"
		input.multiple = true // Allow multiple file selection

		input.addEventListener("change", async (event) => {
			const files = event.target.files

			try {
				const result = await postUploadProgramImage({ weekly_str: weeklyStr, files }) // Await the upload mutation
				console.log("Upload success:", result)
				dispatch(
					openSnackbar({
						severity: "success",
						message: "이미지가 성공적으로 업로드되었습니다.",
					})
				)
				refetch()
			} catch (error) {
				console.error("Upload failed:", error)
			}
		})

		input.click()
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<FormProvider {...form}>
				<MDBox pb={3}>
					<FormControl sx={{ paddingY: 2 }}>
						<DatePicker
							name="date"
							label="날짜를 선택해 주세요"
							control={control}
							disableFuture={false}
						/>
					</FormControl>
					{imageList.length === 0 && (
						<UploadImage image={image} onImageClick={imageUploadHandler} />
					)}
					{imageList.length > 0 && (
						<ScrollableImageList
							images={imageList}
							setImages={setImageList}
							weeklyStr={weeklyStr}
						/>
					)}
					{imageList.length > 0 && (
						<MDBox display="flex" justifyContent="center">
							<MDButton variant="contained" color="info" onClick={imageUploadHandler}>
								이미지 추가 업로드하기
							</MDButton>
						</MDBox>
					)}
				</MDBox>
			</FormProvider>
			<Footer />
		</DashboardLayout>
	)
}

export default ProgramScreen
