import FormLoading from "components/FormLoading"
import { useLocation } from "react-router-dom"

export default function fetcherHOC(Component, fetcher, params) {
	return function FetcherHOC(props) {
		const { state } = useLocation()

		const paramsFromState = params.reduce((acc, key) => {
			acc[key] = state[key]
			return acc
		}, {})

		const response = fetcher({ ...props, ...paramsFromState })
		const { data, isLoading } = response

		return isLoading ? <FormLoading /> : <Component {...props} response={data} />
	}
}

export function FetchById(Component, fetcher, params) {
	return fetcherHOC(Component, fetcher, params)
}
