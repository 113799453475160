import { Icon } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { FaArrowLeft, FaArrowRight, FaTrashAlt } from "react-icons/fa"

// Import icons

// Import trash can icon

const ImageWithControls = ({ img, index, onDelete, onMoveLeft, onMoveRight, isLast }) => {
	return (
		<div style={{ position: "relative", width: "400px" }}>
			{/* Trash Can Icon for Deleting */}
			<Icon
				onClick={() => onDelete(index)}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px",
					cursor: "pointer",
					color: "black",
					zIndex: 1,
				}}
			>
				clear
			</Icon>

			{/* Left Arrow Icon */}
			{index > 0 && (
				<FaArrowLeft
					onClick={() => onMoveLeft(index)}
					style={{
						position: "absolute",
						top: "50%",
						left: "10px",
						cursor: "pointer",
						color: "black",
						zIndex: 1,
						transform: "translateY(-50%)",
					}}
				/>
			)}

			{/* Right Arrow Icon */}
			{!isLast && (
				<FaArrowRight
					onClick={() => onMoveRight(index)}
					style={{
						position: "absolute",
						top: "50%",
						right: "10px",
						cursor: "pointer",
						color: "black",
						zIndex: 1,
						transform: "translateY(-50%)",
					}}
				/>
			)}

			{/* Image */}
			<img
				src={img.image_url}
				alt={`Image ${img.order}`}
				style={{
					width: "400px",
					height: "auto",
					borderRadius: "15px",
					display: "block",
				}}
			/>

			{/* Image Order Number */}
			<div style={{ textAlign: "center", marginTop: "5px", fontWeight: "bold" }}>{img.order}</div>
		</div>
	)
}

// Define PropTypes
ImageWithControls.propTypes = {
	img: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	onDelete: PropTypes.func.isRequired,
	onMoveLeft: PropTypes.func.isRequired,
	onMoveRight: PropTypes.func.isRequired,
	isLast: PropTypes.bool.isRequired,
}

export default ImageWithControls
