import { Dialog, List, ListItem, ListItemText } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { DATE_FORMAT } from "constant"
import { format } from "date-fns"
import { isEmpty, map, omitBy } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { adminApi } from "services/modules"

const UncheckedGroupModal = ({ open, onClose }) => {
	const { watch } = useFormContext()
	const { date: watchedDate, parish: watchedParish } = watch()

	const memoizedData = useMemo(() => {
		const weekly_date = watchedDate ? format(watchedDate, DATE_FORMAT) : ""
		const data = {
			weekly_date: weekly_date,
			parish_id: watchedParish,
		}

		return omitBy(data, isEmpty)
	}, [watchedDate, watchedParish])

	const [trigger, { data: { data = [] } = {} }] = adminApi.useLazyGetAttendanceUncheckedQuery()

	const fetchData = useCallback(async () => {
		await trigger(memoizedData)
	}, [trigger, memoizedData])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	return (
		<Dialog open={open} onClose={onClose}>
			<MDBox p={2}>
				<MDTypography component="p" variant="body1">
					출석체크를 하지 않은 속회 리스트
				</MDTypography>
				<MDBox mt={2}>
					{data?.length !== 0 ? (
						<List dense={true}>
							{map(data, ({ group_name, parish_name }, index) => (
								<ListItem key={`unchecked_${index}`}>
									<ListItemText primary={`${parish_name} ${group_name}`} />
								</ListItem>
							))}
						</List>
					) : (
						<MDTypography component="p" variant="body2" sx={{ textAlign: "center" }}>
							출석체크를 하지 않은 속회가 없습니다 😆
						</MDTypography>
					)}
				</MDBox>
			</MDBox>
		</Dialog>
	)
}

UncheckedGroupModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
}

UncheckedGroupModal.defaultProps = {
	open: false,
	onClose: () => {},
}

export default UncheckedGroupModal
